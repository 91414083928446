import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import userLogo from "../../../assets/img/user.png"
import Breadcrumb from '../../../component/Home/Breadcrumb';

const Users = () => {

    let baseURL = ""
    if (process.env.NODE_ENV === "development") {
        baseURL = 'http://localhost:3002'
    } else {
        baseURL = 'https://simpeg.unespadang.ac.id'
    }
    const { userID } = useParams();

    const [users, setUsers] = useState([]);
    const [activeTab, setActiveTab] = useState('penelitian');

    const getUsers = async () => {
        try {
            const response = await axios.get(`api/user/${userID}`);
            setUsers(response.data);


            // if (pegawai === 'karyawan') {
            //     setDataPegawai(response.data.karyawan)
            // } else if (pegawai === 'dosen') {
            //     setDataPegawai(response.data.dosen)

            // }

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, []);

    const breadcrumbPaths = [
        { name: 'Home', link: '/' },
        { name: 'User', link: '' },
        { name: users.nama, link: '' }
    ];
    return (
        <>

            <Breadcrumb paths={breadcrumbPaths} />

            <div className=" ">
                <div className="container pt-8 mx-auto  p-5">
                    <div className=" md:grid md:grid-cols-4 md:-mx-2 ">
                        <div className=" md:mr-4 w-full h-full ">
                            <div className="bg-white p-3 h-full border-t-4 border-[#51242c]">
                                <div className="image overflow-hidden">
                                    <img className="h-auto w-full mx-auto"
                                        src={users.foto ? baseURL + '/images/' + users.foto : userLogo}
                                        alt="" />
                                </div>
                                <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">{users.nama}</h1>
                                <h3 className="text-gray-600 font-lg text-semibold leading-6">{users.email}</h3>
                                <ul
                                    className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                    <li className="flex items-center py-3">
                                        <span>Status</span>
                                        <span className="ml-auto"><span
                                            className="bg-green-500 py-1 px-2 rounded text-white text-sm">Active</span></span>
                                    </li>
                                    {/* <li className="flex items-center py-3">
                                        <span>Tanggal Bergabung</span>
                                        <span className="ml-auto">Nov 07, 2016</span>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="my-4"></div>
                        </div>
                        <div className=" md:ml-4 col-span-3 w-full   ">
                            <div className="bg-white p-3 shadow-sm rounded-sm">
                                <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                    <span clas="text-green-500">
                                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </span>
                                    <span className="tracking-wide text-teal-600">Data Pribadi</span>
                                </div>
                                <div className="text-gray-700">

                                    <div className=' py-4 md:px-8 px-4 text-sm text-left  w-full'>
                                        <table className=' leading-6 '>
                                            <tbody>
                                                <tr className=' align-top'>
                                                    <td className=' font-semibold '>Alamat</td>
                                                    <td className=' w-4'>:</td>
                                                    <td className=' text-left font-light '>{users.alamat}</td>
                                                </tr>
                                                <tr className=' align-top'>
                                                    <td className=' min-w-36 font-semibold'>Jenis Kelamin</td>
                                                    <td className=' w-4'>:</td>
                                                    <td className=' text-left font-light'>
                                                        {users.jenisKelamin === 'L' ? 'Laki-laki' :
                                                            users.jenisKelamin === 'P' ? 'Perempuan' :
                                                                'Tidak Diketahui'}
                                                    </td>
                                                </tr>
                                                <tr className=' align-top'>
                                                    <td className=' min-w-36 font-semibold'>Tanggal Lahir</td>
                                                    <td className=' w-4'>:</td>
                                                    <td className=' text-left font-light'>{users.tanggalLahir}</td>
                                                </tr>
                                                <tr className=' align-top'>
                                                    <td className=' min-w-36 font-semibold'>Handphone</td>
                                                    <td className=' w-4'>:</td>
                                                    <td className=' text-left font-light'>{users.telepon}</td>
                                                </tr>

                                                <tr className=' align-top'>
                                                    <td className=' min-w-36 font-semibold'>Handphone</td>
                                                    <td className=' w-4'>:</td>
                                                    <td className=' text-left font-light'>{users.telepon}</td>
                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-3 my-4 shadow-sm rounded-sm">
                                <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                    <span clas="text-green-500">
                                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </span>
                                    <span className="tracking-wide text-teal-600">Data pendidikan</span>
                                </div>
                                <div className="text-gray-700">

                                    <div className=' py-4 px-8  text-sm text-left  w-full'>
                                        <div className="flex w-full  items-center justify-center">
                                            <div className="w-full  overflow-x-auto">
                                                <table className="min-w-full bg-white shadow-md rounded-xl">
                                                    <thead>
                                                        <tr className="bg-gray-100 text-gray-700">
                                                            <th className="py-3 px-4 text-left">Nama Institusi</th>
                                                            <th className="py-3 px-4 text-left">Jurusan</th>
                                                            <th className="py-3 px-4 text-left">jenjang</th>
                                                            <th className="py-3 px-4 text-left">Tahun Lulus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-blue-gray-900">
                                                        {users.pendidikan?.length === 0 && (
                                                            <tr>
                                                                <td colSpan={4} className=' text-center p-8'>
                                                                    "Tidak ada data"
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {users.pendidikan && users.pendidikan.map((pendidikan, index) => (
                                                            <tr className="border-b border-blue-gray-200" key={index}>
                                                                <td className="py-3 px-4">{pendidikan.namaInstitusi}</td>
                                                                <td className="py-3 px-4">{pendidikan.jurusan}</td>
                                                                <td className="py-3 px-4">{pendidikan.jenjang}</td>
                                                                <td className="py-3 px-4">{pendidikan.tahunLulus}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {users.karyawan && (

                                <div className="bg-white p-3 my-4 shadow-sm rounded-sm">
                                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                        <span clas="text-green-500">
                                            <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                            </svg>
                                        </span>
                                        <span className="tracking-wide text-teal-600">Data Karyawan</span>
                                    </div>
                                    <div className="text-gray-700">

                                        <div className=' py-4 md:px-8 px-4 text-sm text-left  w-full'>
                                            <table className=' leading-6 '>
                                                <thead></thead>
                                                <tbody>
                                                    <tr className=' align-top'>
                                                        <td className=' font-semibold '>Devisi</td>
                                                        <td className=' w-4'>:</td>
                                                        <td className=' text-left font-light '>{users.karyawan.devisi.nama_devisi}</td>
                                                    </tr>

                                                    <tr className=' align-top'>
                                                        <td className=' min-w-36 font-semibold'>Jabatan</td>
                                                        <td className=' w-4'>:</td>
                                                        <td className=' text-left font-light'>{users.karyawan.jabatan}</td>
                                                    </tr>
                                                    <tr className=' align-top'>
                                                        <td className=' min-w-36 font-semibold'>Status Karyawan</td>
                                                        <td className=' w-4'>:</td>
                                                        <td className=' text-left font-light'>{users.karyawan.statusKaryawan}</td>
                                                    </tr>
                                                    <tr className=' align-top'>
                                                        <td className=' min-w-36 font-semibold'>Tanggal Bergabung</td>
                                                        <td className=' w-4'>:</td>
                                                        <td className=' text-left font-light'>{users.karyawan.tanggalBergabung}</td>
                                                    </tr>
                                                    <tr className=' align-top'>
                                                        <td className=' min-w-36 font-semibold'>Gaji</td>
                                                        <td className=' w-4'>:</td>
                                                        <td className=' text-left font-light'>{users.karyawan.gaji}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot></tfoot>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {users.dosen && (
                                <div className="bg-white p-3 my-4 shadow-sm rounded-sm">
                                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                        <span className="text-green-500">
                                            <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                            </svg>
                                        </span>
                                        <span className="tracking-wide text-teal-600">Data Dosen</span>
                                    </div>
                                    <div className="text-gray-700">
                                        <div className=' py-4 md:px-8 px-4  text-sm text-left  w-full'>
                                            <table className=' leading-6 '>
                                                <tbody>
                                                    <tr className=' align-top'>
                                                        <td className=' font-semibold '>NIDN</td>
                                                        <td className=' w-4'>:</td>
                                                        <td className=' text-left font-light '>{users.dosen.nidn}</td>
                                                    </tr>
                                                    <tr className=' align-top'>
                                                        <td className=' min-w-36 font-semibold'>Jabatan</td>
                                                        <td className=' w-4'>:</td>
                                                        <td className=' text-left font-light'>{users.dosen.jabatan}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="text-gray-700 border mt-8">
                                                <div className="md:flex  w-full">
                                                    <button onClick={() => setActiveTab('penelitian')} className={`py-2 px-4 w-full font-bold text-xl uppercase  ${activeTab === 'penelitian' ? 'bg-teal-600 text-white' : 'bg-gray-200'}`}>Penelitian</button>
                                                    <button onClick={() => setActiveTab('pengabdian')} className={`py-2 px-4 w-full font-bold text-xl uppercase  ${activeTab === 'pengabdian' ? 'bg-teal-600 text-white' : 'bg-gray-200'}`}>Pengabdian</button>
                                                    <button onClick={() => setActiveTab('publikasi')} className={`py-2 px-4  w-full font-bold text-xl uppercase ${activeTab === 'publikasi' ? 'bg-teal-600 text-white' : 'bg-gray-200'}`}>Publikasi</button>
                                                </div>
                                                <div className='py-4 overflow-x-auto mx-4  text-sm text-left '>
                                                    {activeTab === 'penelitian' && (
                                                        <table className=" bg-white ">
                                                            <thead>
                                                                <tr className="bg-gray-100 text-gray-700">
                                                                    <th className="py-3 px-4 text-left">No.</th>
                                                                    <th className="py-3 px-4 text-left">Judul Penelitian</th>
                                                                    <th className="py-3 px-4 text-left">Link Penelitian</th>
                                                                    <th className="py-3 px-4 text-left">Tahun</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {users.penelitian?.length === 0 && (
                                                                    <tr>
                                                                        <td colSpan={4} className=' text-center p-8'>
                                                                            "Tidak ada data"
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {users.dosen.penelitian && users.dosen.penelitian.map((penelitian, index) => (
                                                                    <tr className="border-b border-blue-gray-200" key={index}>
                                                                        <td className="py-3 px-4">{index + 1}</td>
                                                                        <td className="w-3/4 py-3 px-4 font-semibold">
                                                                            {
                                                                                penelitian.linkPenelitian ? (
                                                                                    <a target='blank' className=' text-blue-500 hover:text-blue-600' href={`${penelitian.linkPenelitian}`}>
                                                                                        {penelitian.judulPenelitian}
                                                                                    </a>
                                                                                ) : (
                                                                                    <span>
                                                                                        {penelitian.judulPenelitian}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td className="py-3 break-all w-3/6  px-4">
                                                                            {penelitian.linkPenelitian || '-'}
                                                                        </td>
                                                                        <td className="py-3 px-4">{penelitian.tahunPenelitian}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot></tfoot>
                                                        </table>
                                                    )}
                                                    {activeTab === 'pengabdian' && (
                                                        <table className=" table-auto bg-white ">
                                                            <thead>
                                                                <tr className="bg-gray-100 text-gray-700">
                                                                    <th className="py-3 px-4 text-left">No.</th>
                                                                    <th className="py-3 px-4 text-left">Judul pengabdian</th>
                                                                    <th className="py-3 px-4 text-left">Link pengabdian</th>
                                                                    <th className="py-3 px-4 text-left">Tahun</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {users.dosen.pengabdian && users.dosen.pengabdian.map((pengabdian, index) => (
                                                                    <tr className="border-b border-blue-gray-200" key={index}>
                                                                        <td className="py-3 px-4">{index + 1}</td>
                                                                        <td className="w-3/4 py-3 px-4 font-semibold">
                                                                            {
                                                                                pengabdian.linkPengabdian ? (
                                                                                    <a target='blank' className=' text-blue-500 hover:text-blue-600' href={`${pengabdian.linkPengabdian}`}>
                                                                                        {pengabdian.judulPengabdian}
                                                                                    </a>
                                                                                ) : (
                                                                                    <span>
                                                                                        {pengabdian.judulPengabdian}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td className="py-3 break-all w-3/6  px-4">
                                                                            {pengabdian.linkPengabdian || '-'}
                                                                        </td>
                                                                        <td className="py-3 px-4">{pengabdian.tahunPengabdian}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot></tfoot>

                                                        </table>
                                                    )}
                                                    {activeTab === 'publikasi' && (
                                                        <table className="min-w-full bg-white ">
                                                            <thead>
                                                                <tr className="bg-gray-100 text-gray-700">
                                                                    <th className="py-3 px-4 text-left">No.</th>
                                                                    <th className="py-3 px-4 text-left">Judul publikasi</th>
                                                                    <th className="py-3 px-4 text-left">Link publikasi</th>
                                                                    <th className="py-3 px-4 text-left">Tahun</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {users.dosen.publikasi && users.dosen.publikasi.map((publikasi, index) => (
                                                                    <tr className="border-b border-blue-gray-200" key={index}>
                                                                        <td className="py-3 px-4">{index + 1}</td>

                                                                        <td className="w-3/4 py-3 px-4 font-semibold">
                                                                            {
                                                                                publikasi.linkPublikasi ? (
                                                                                    <a target='blank' className=' text-blue-500 hover:text-blue-600' href={`${publikasi.linkPublikasi}`}>
                                                                                        {publikasi.judulPublikasi}
                                                                                    </a>
                                                                                ) : (
                                                                                    <span>
                                                                                        {publikasi.judulPublikasi}
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td className="py-3 break-all w-3/6  px-4">
                                                                            {publikasi.linkPublikasi || '-'}
                                                                        </td>
                                                                        <td className="py-3 px-4">{publikasi.tahunPublikasi}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot></tfoot>

                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Users
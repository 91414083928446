import { FaCamera } from 'react-icons/fa';
import userLogo from '../../../assets/img/user.png'
import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const PersonalDataForm = ({ formData, onChange, onSubmit, header }) => {

	let baseURL = ""
	if (process.env.NODE_ENV === "development") {
		baseURL = 'http://localhost:3002'
	} else {
		baseURL = 'https://simpeg.unespadang.ac.id'
	}
	const { userID } = useParams();

	const handleUploadFile = async (file) => {
		const formData = new FormData();
		formData.append('images', file); // Append the file to the form data
		formData.append('userID', userID); // Include userID in the form data

		try {
			await axios.post('api/user/foto', formData, header);
			Swal.fire({
				icon: "success",
				title: "File berhasil diunggah",
				showConfirmButton: false,
				timer: 1500
			});
		} catch (error) {
			console.error('Error uploading file:', error);
			Swal.fire({
				icon: "error",
				title: "Gagal mengunggah file",
				text: error.message,
			});
		}
	};
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				document.getElementById('profile').src = reader.result; // Update the image source
			};
			reader.readAsDataURL(file); // Read the file as a data URL
			handleUploadFile(file); // Call the upload function
		}
	};
	return (
		<form onSubmit={onSubmit} className="">
			<div className='grid grid-cols-2'>
				<div className='p-2'>
					<div className='w-full relative flex justify-center'>
						<img id='profile' className='rounded-full object-cover w-60 h-60' src={formData.foto ? baseURL + '/images/' + formData.foto : userLogo} alt="" />
						<div id='foto' className='cursor-pointer drop-shadow-md text-gray-200 text-5xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' onClick={() => document.getElementById('fileInput').click()}>
							<FaCamera />
						</div>
					</div>
					<input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
					<h2 className=" mb-4 mt-2 font-light">Foto Profile</h2>
					<div className="mb-4">
						<label className="flex text-sm font-medium text-gray-700">Nama:</label>
						<input type="text" name="nama" value={formData.nama || ''} onChange={onChange} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
					</div>
					<div className="mb-4">
						<label className="flex text-sm font-medium text-gray-700">Email:</label>
						<input type="email" name="email" value={formData.email || ''} onChange={onChange} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
					</div>
					<div className="mb-4">
						<label className="flex text-sm font-medium text-gray-700">Jenis Kelamin:</label>
						<select name="jenisKelamin" value={formData.jenisKelamin || ''} onChange={onChange} className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
							<option value="">Pilih</option>
							<option value="L">Laki-laki</option>
							<option value="P">Perempuan</option>
						</select>
					</div>
					<div className="mb-4">
						<label className="flex text-sm font-medium text-gray-700">Tanggal Lahir:</label>
						<input type="date" name="tanggalLahir" value={formData.tanggalLahir || ''} onChange={onChange} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
					</div>
				</div>
				<div className='p-2'>
					<div className="mb-4">
						<label className="flex text-sm font-medium text-gray-700">Telepon:</label>
						<input type="tel" name="telepon" value={formData.telepon || ''} onChange={onChange} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
					</div>
					<div className="mb-4">
						<label className="flex text-sm font-medium text-gray-700">Alamat:</label>
						<textarea name="alamat" value={formData.alamat || ''} onChange={onChange} className="mt-1 block w-full p-2 border border-gray-300 rounded-md"></textarea>
					</div>
				</div>
			</div>
			<button type="submit" className="mt-4 w-full bg-[#31525e] text-white font-semibold py-2 rounded-md hover:bg-[#597d8b]">Simpan</button>
		</form>
	);
};

export default PersonalDataForm;
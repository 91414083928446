import { Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./page/Login";
import AdminLayout from "./layout/AdminLayout";
import HomeLayout from "./layout/HomeLayout";

function App() {
  return (
    <div className="App">

      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/*" element={< HomeLayout />} />
        <Route path="/login" element={<Login />} />

      </Routes>
    </div>
  );
}

export default App;
import { Route, Routes, Navigate } from "react-router-dom";
import { isAuth } from "../helper/auth";
import { useCookies } from "react-cookie";
import DashboardAdmin from "../page/Admin/DashboardAdmin";
import Devisi from "../page/Admin/Devisi";
import SidebarAdmin from "../component/Admin/SidebarAdmin";
import Pegawai from "../page/Admin/Pegawai";
import PegawaiEdit from "../page/Admin/Pegawai.edit";
import PegawaiAdd from "../page/Admin/Pegawai.add";
import Fakultas from "../page/Admin/Fakultas";
import Users from "../page/Admin/Users";

const AdminLayout = () => {
    const [cookies] = useCookies(['token']);
    const user = isAuth(cookies.token);

    const data = {
        user: {
            id: user?.id,
            username: user?.username,
            nama: user?.nama,
            email: user?.email,
            role: user?.role,
        },
        token: cookies.token
    }

    return (
        <>
            {!user || !user?.role === 'admin' ? (<Navigate to="/login" />) : (
                <div>
                    <SidebarAdmin data={data} />
                    <div className=" bg-[#cad4e0] overflow-x-hidden md:ml-64">
                        <div className="  md:m-8 m-0 " >
                            <Routes >
                                <Route path="/" element={<DashboardAdmin data={data} />} />
                                <Route path="/dashboard" element={<DashboardAdmin data={data} />} />
                                <Route path="/pegawai" element={<Pegawai data={data} />} />
                                <Route path="/pegawai/add" element={<PegawaiAdd data={data} />} />
                                <Route path="/pegawai/:userID" element={<PegawaiEdit data={data} />} />
                                <Route path="/devisi" element={<Devisi data={data} />} />
                                <Route path="/fakultas" element={<Fakultas data={data} />} />
                                <Route path="/users" element={<Users data={data} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default AdminLayout;
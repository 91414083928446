import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import PersonalDataForm from '../../component/Admin/Forms/PersonalDataForm';
import EmployeeDataForm from '../../component/Admin/Forms/EmployeeDataForm';
import EducationDataForm from '../../component/Admin/Forms/EducationDataForm';
const PegawaiEdit = ({ data }) => {

    const header = {
        headers: { 'Authorization': `Bearer ${data.token}` }
    }
    const location = useLocation();
    const dataState = location.state || ''; // Handle jika state kosong

    const { userID } = useParams();
    const [formDataPribadi, setFormDataPribadi] = useState({});
    const [activeTab, setActiveTab] = useState(dataState.activeTab || 'formDataPribadi'); // State for active tab

    useEffect(() => {
        const fetchDataPribadi = async () => {
            try {
                const response = await axios.get(`api/user/${userID}`, header);
                setFormDataPribadi(response.data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataPribadi();
        // eslint-disable-next-line
    }, [userID]);

    const handleChangeDataPribadi = (e) => {
        const { name, value } = e.target;
        setFormDataPribadi({ ...formDataPribadi, [name]: value || '' });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(`api/user/${userID}`, formDataPribadi, header);
            Swal.fire({
                icon: "success",
                title: "Tersimpan",
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };





    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };





    return (
        <div className='p-6 bg-white rounded-lg shadow-md'>
            <div id='tabs' className="flex uppercase w-full  mb-4">
                <div
                    onClick={() => handleTabChange('formDataPribadi')}
                    className={` cursor-pointer w-full font-bold py-2 px-4  ${activeTab === 'formDataPribadi' ? 'bg-[#51242c] text-white' : 'bg-gray-200 text-[#a83135] hover:bg-gray-300'}`}
                >
                    Data Pribadi
                </div>
                <div
                    onClick={() => handleTabChange('formDataPendidikan')}
                    className={` cursor-pointer w-full font-bold py-2 px-4  ${activeTab === 'formDataPendidikan' ? 'bg-[#51242c] text-white' : 'bg-gray-200 text-[#a83135] hover:bg-gray-300'}`}
                >
                    Data Pendidikan
                </div>
                <div
                    onClick={() => handleTabChange('formDataPegawai')}
                    className={` cursor-pointer w-full font-bold py-2 px-4 ${activeTab === 'formDataPegawai' ? 'bg-[#51242c] text-white' : 'bg-gray-200 text-[#a83135] hover:bg-gray-300'}`}
                >
                    Data Kepegawaian
                </div>
            </div>

            {activeTab === 'formDataPribadi' && (
                <PersonalDataForm
                    formData={formDataPribadi}
                    onChange={handleChangeDataPribadi}
                    onSubmit={handleSubmit}
                    header={header}
                />
            )}
            {activeTab === 'formDataPendidikan' && (
                <EducationDataForm
                    pendidikan={formDataPribadi.pendidikan}
                    header={header}
                />
            )}
            {activeTab === 'formDataPegawai' && (
                <EmployeeDataForm
                    formDataDosen={formDataPribadi.dosen}
                    formDataKaryawan={formDataPribadi.karyawan}
                    header={header}
                />
            )}
        </div>
    );
};

export default PegawaiEdit;
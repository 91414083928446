import { FaUserGraduate } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Breadcrumb from '../../../component/Home/Breadcrumb';
import LoadingSmall from '../../../component/LoadingSmall';

const Dosen = () => {
	const [fakultas, setFakultas] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchFakultas = async () => {
			try {
				const response = await axios.get('api/fakultas');
				setFakultas(response.data);
			} catch (error) {
				console.error('Error fetching fakultas:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchFakultas();
	}, []);

	const breadcrumbPaths = [
		{ name: 'Home', link: '/' },
		{ name: 'Fakultas', link: '#' }
	];

	return (
		<>
			<Breadcrumb paths={breadcrumbPaths} />
			<div className="w-full flex justify-center h-auto">
				<div className='container'>
					<div className="animated fadeIn md:gridm gkrid-cols-3 md:p-8 p-4">
						{loading ? (
							<div className="flex justify-center min-h-[300px] items-center h-full">
								<LoadingSmall />
							</div>
						) : (
							fakultas.map((item, index) => (
								<NavLink key={index} state={{ fakultas: item.nama_fakultas }} to={`/fakultas/${item.fakultasID}`} >
									<div className="flex flex-col p-2 bg-gray-50 hover:bg-white shadow m-2 hover:shadow-lg rounded-2xl">
										<div className="flex items-center justify-between">
											<div className="flex items-center">
												<div className=' flex items-center justify-center text-2xl bg-blue-50 w-16 h-16 rounded-2xl p-2 border border-blue-100 text-blue-400 bg-blue-50" '>
													<FaUserGraduate />
												</div>
												<div className="flex flex-col ml-3">
													<div className="text-left font-medium leading-none">{item.nama_fakultas}</div>
												</div>
											</div>
											<button className="flex-no-shrink bg-red-500 px-5 ml-4 py-2 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider border-2 border-red-500 text-white rounded-full">Lihat</button>
										</div>
									</div>
								</NavLink>
							))
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default Dosen
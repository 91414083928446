import { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import Pagination from '../../component/Admin/Pagination';
import { FaPlusCircle, FaTrashAlt, FaEdit } from 'react-icons/fa';
import Modal from 'react-modal';
import Swal from 'sweetalert2'; // Import SweetAlert2

const Fakultas = ({ data }) => {
    const header = {
        headers: { 'Authorization': `Bearer ${data.token}` }
    }
    const [listFakultas, setListFakultas] = useState([]);

    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        getListFakultas();
        // eslint-disable-next-line
    }, [page, limit]);

    const getListFakultas = () => {
        // setLoadingSmall(true)
        axios.get(`api/fakultas/admin?page=${page}&limit=${limit}`, header)
            .then((response) => {
                setMeta(response.data.meta);
                setListFakultas(response.data.items);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    const selectPage = (value) => {
        setPage(value);
    };

    const nextPage = () => {
        if (page < meta.totalPages) {
            setPage(page + 1);
        }
    };

    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const limitPage = (event) => {
        setLimit(Number(event.target.value));
    };


    const closeModal = () => {
        setModalIsOpen(false);
    }


    const handleEdit = (id) => {
        const data = listFakultas.find(item => item.fakultasID === id);
        if (data) {
            setModalData([data]); // Set modal data untuk edit
            setModalIsOpen(true); // Buka modal
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries());

        if (modalData) {
            // Update existing data
            axios.patch(`api/fakultas/${modalData[0].fakultasID}`, data, header)
                .then(() => {
                    getListFakultas(); // Refresh the list
                    closeModal();
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Data telah diperbarui.',
                        icon: 'success',
                        timer: 1000, // Menampilkan alert selama 2 detik
                        showConfirmButton: false // Menyembunyikan tombol OK
                    });
                })
                .catch((error) => {
                    console.error('Error updating data:', error);
                    Swal.fire(
                        'Gagal!',
                        'Terjadi kesalahan saat memperbarui data.',
                        'error'
                    );
                });
        } else {
            // Add new data
            axios.post('api/fakultas', data, header)
                .then(() => {
                    getListFakultas(); // Refresh the list
                    closeModal();
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Data telah ditambahkan.',
                        icon: 'success',
                        timer: 1000, // Menampilkan alert selama 2 detik
                        showConfirmButton: true // Menyembunyikan tombol OK
                    });
                })
                .catch((error) => {
                    console.error('Error adding data:', error);
                    Swal.fire(
                        'Gagal!',
                        'Terjadi kesalahan saat menambahkan data.',
                        'error'
                    );
                });
        }
    };

    const openAddModal = () => {
        setModalData(null); // Reset modal data for new entry
        setModalIsOpen(true); // Open modal
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Apakah Anda yakin?',
            text: "Data ini akan dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya, hapus!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`api/fakultas/${id}`, header)
                    .then(() => {
                        getListFakultas(); // Refresh the list after deletion
                        Swal.fire({
                            title: 'Sukses!',
                            text: 'Data berhasil Dihapus.',
                            icon: 'success',
                            timer: 1000, // Menampilkan alert selama 2 detik
                            showConfirmButton: true // Menyembunyikan tombol OK
                        });
                    })
                    .catch((error) => {
                        console.error('Error deleting data:', error);
                        Swal.fire(
                            'Gagal!',
                            'Terjadi kesalahan saat menghapus data.',
                            'error'
                        );
                    });
            }
        });
    };


    return (
        <>

            <div className="flex py-2 px-4 md:px-0 bg-white  flex-col">

                <div className="mb-4 mt-6 py-2 px-0 md:px-6 ">
                    <button className='w-full flex justify-center items-center h-12  text-white bg-[#51242c]' onClick={openAddModal} type="button"><span className='p-1'><FaPlusCircle /></span> TAMBAH FAKULTAS</button>
                </div>
                <div className="overflow-x-auto ">
                    <div className="inline-block min-w-full md:px-6 px-0">
                        <div className="overflow-hidden ">
                            <table className="border min-w-full">
                                <thead className="bg-[#31525e] border-b">
                                    <tr>
                                        <th scope="col" className="text-xs  font-bold text-white pl-4 py-2 text-center">No.</th>
                                        <th scope="col" className="text-xs font-bold text-white px-3 py-4 text-left">NAMA FAKULTAS</th>

                                        <th scope="col" className="text-xs font-bold text-white px-3 py-4 text-center">Action</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {listFakultas ? (
                                        listFakultas.map((item, index) => (
                                            <tr key={index} className={` cursor-pointer border-b bg-white`}>
                                                <td className="   text-xs font-medium text-gray-900">{(page - 1) * limit + index + 1}</td>
                                                <td className="text-sm text-gray-900  px-3 py-3 font-semibold whitespace-nowrap text-left">{item.nama_fakultas}</td>
                                                <td className="text-xs text-gray-900 font-light  whitespace-nowrap text-left">
                                                    <div className=' space-x-2 flex p-1 justify-center items-center'>
                                                        <span className=' shadow hover:bg-slate-200 cursor-pointer rounded bg-[#f7f7f7] p-2 text-xl text-[#4a90e2]' onClick={() => handleEdit(item.fakultasID)}><FaEdit /></span>
                                                        <span className=' shadow hover:bg-slate-200 cursor-pointer rounded bg-[#f7f7f7] p-2 text-xl text-[#ef7e7e]' onClick={() => handleDelete(item.fakultasID)}><FaTrashAlt /></span>
                                                    </div>
                                                </td>


                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="bg-white border-b">

                                            <td colSpan={8} className="px-6 py-4 whitespace-nowrap text-2xl font-medium text-gray-300">
                                                <div className='flex justify-center'>
                                                    <div className=' items-center text-center'>
                                                        Data Periode Tidak Ditemukan
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Pagination
                    currentPage={page}
                    totalPages={meta.totalPages}
                    limitPage={limitPage}
                    selectPage={selectPage}
                    nextPage={nextPage}
                    prevPage={prevPage}
                />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Detail Modal"
                ariaHideApp={false}
                className="fixed w-screen inset-0 flex items-center justify-center h-screen p-4 bg-gray-500 bg-opacity-50"
                overlayClassName="fixed z-50 w-screen h-screen inset-0 bg-gray-800 bg-opacity-75"
            >
                {/* {modalData && modalData?.map(item => ( */}
                <div className=' shadow-lg  bg-white md:w-4/5 w-full h-auto p-4'>
                    <div className=' text-center pt-2 text-xl font-semibold'>DETAIL FAKULTAS</div>
                    <div className="bg-white h-5/6 overflow-y-auto   max-w-full  m-3  ">
                        <div className=' md:w-4/5 w-full  mx-auto '>
                            <div className="grid  place-items-center">
                                <div className="w-full  bg-white  ">
                                    {/* <h1 className="text-xl font-semibold">Hello there ?, <span className="font-normal">please fill in your information to continue</span></h1> */}
                                    <form id='form-fakultas' className="mt-6" onSubmit={handleSubmit}>
                                        <label htmlFor="nama_fakultas" className="block mt-2 text-xs font-semibold text-gray-600 uppercase">Nama Fakultas</label>
                                        <input id="nama_fakultas" type="text" name="nama_fakultas" placeholder="nama fakultas" className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required defaultValue={modalData ? modalData[0].nama_fakultas : ''} />

                                        <div className='flex justify-center items-center mt-4 gap-2'>
                                            <button type="submit" className="w-full py-3 my-2 font-medium tracking-widest text-white uppercase bg-[#31525e] shadow-lg focus:outline-none hover:bg-[#517988] hover:shadow-none">
                                                Simpan
                                            </button>
                                            <button onClick={closeModal} className="w-full py-3 my-2 font-medium tracking-widest text-white uppercase bg-[#51242c] shadow-lg focus:outline-none hover:bg-[#704049] hover:shadow-none">
                                                Batal
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* ))} */}
            </Modal>
        </>
    );
};

export default Fakultas;

//Get user info from localstorage
export const allowUser = (users) => {
    if (users.user.status === 'APL' || users.user.status === 'BLI' || users.user.status === 'REG') {
        setLocalStorage("token", users.token);
        return true;
    }
    return false;
}
export const allowAdmin = (users) => {
    if (users.user.role === 'admin') {
        setLocalStorage("token", users.token);
        return true;
    }
    return false;
}
export const isAuth = (data) => {
    if (data) {
        const token = data
        const parseJwt = (token) => {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        };
        const decodedJwt = parseJwt(token);

        if (decodedJwt?.exp * 1000 < new Date().getTime()) {
            localStorage.clear();
            return false;
        } else {
            return decodedJwt;
        }
    } else {
        return false
    }

}
export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));

};
export const removeLocalStorage = () => {
    localStorage.clear();
};


import { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import userLogo from "../../../assets/img/user.png"
import Breadcrumb from '../../../component/Home/Breadcrumb';
import LoadingSmall from '../../../component/LoadingSmall';
const DosenList = () => {
	const location = useLocation();
	const dataState = location.state || ''; // Handle jika state kosong

	const [loading, setLoading] = useState(true);
	let baseURL = ""
	if (process.env.NODE_ENV === "development") {
		baseURL = 'http://localhost:3002'
	} else {
		baseURL = 'https://simpeg.unespadang.ac.id'
	}
	const { fakultasID } = useParams();
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const fetchUsers = async () => {
			setLoading(true);
			try {
				const response = await axios.get(`api/user/fakultas/${fakultasID}`);
				setUsers(response.data);
			} catch (error) {
				console.error('Error fetching users:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchUsers();
	}, [fakultasID]);

	const breadcrumbPaths = [
		{ name: 'Home', link: '/' },
		{ name: 'Fakultas', link: '/dosen' },
		{ name: dataState.fakultas || fakultasID, link: '' }
	];
	return (
		<>
			<Breadcrumb paths={breadcrumbPaths} />
			<div className="w-full flex justify-center items-center h-auto">
				<div className="relative flex justify-center  container  md:py-6 md:min-h-[calc(100vh-19rem)] min-h-[calc(100vh-25rem)] ">
					<div className="bg-cover  w-full flex justify-center ">
						<div className="grid grid-cols-2  md:grid-cols-4 text-center p-2 mx-auto">
							{
								loading ? (
									<div className="flex col-span-3 justify-center min-h-[300px] items-center h-full">
										<LoadingSmall />
									</div>
								) : (

									<>

										{users.length === 0 && (
											<div className=' col-span-4  text-gray-400 flex justify-center items-center   w-full text-4xl'>
												Tidak ada data
											</div>
										)}
										{users.map((user, index) => (
											<article
												key={index}
												className=" h-44 md:h-auto bg-white md:m-2 p-4 m-4 shadow transition duration-300 group transform hover:-translate-y-2 hover:shadow-2xl rounded-2xl cursor-pointer border">

												<div className="relative md:mb-4 rounded-2xl">
													<div className=' w-full flex justify-center items-center'>
														<img className="md:h-60 md:w-60 h-20 w-20  rounded-2xl  object-cover transition-transform duration-300 transform group-hover:scale-105"
															src={user.foto ? baseURL + '/images/' + user.foto : userLogo} alt="" />
													</div>

													<NavLink state={{ pegawai: 'karyawan', fakultasID }} className="flex justify-center items-center bg-gray-700 bg-opacity-80 z-10 absolute top-0 left-0 w-full h-full text-white rounded-2xl opacity-0 transition-all duration-300 transform group-hover:scale-105 text-xl group-hover:opacity-100"
														to={`/user/${user.userID}`} target="_self" rel="noopener noreferrer">
														Detail

													</NavLink>
												</div>
												<div className="flex justify-between items-center w-full md:pb-4 mb-auto">
													<div className="flex items-center">
														<div className="flex leading-3">
															<div className="">
																<p className=" text-xs md:text-sm text-left flex font-semibold">{user.nama}</p>
																<p className=" text-xs md:text-sm flex text-gray-500"> {user.jabatan}</p>
															</div>
														</div>
													</div>

												</div>

											</article>
										))}


									</>

								)
							}

						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default DosenList
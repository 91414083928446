import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaSave, FaTrashAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const EducationDataForm = ({ header }) => {
	const { userID } = useParams();

	const [riwayatPendidikan, setRiwayatPendidikan] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	useEffect(() => {
		fetchPendidikan()
		// eslint-disable-next-line
	}, [userID])

	const fetchPendidikan = async () => { // New function to fetch devisi
		try {
			const response = await axios.get(`api/pendidikan/users/${userID}`, header); // Adjust the API endpoint as needed
			if (response.data) {
				setRiwayatPendidikan(response.data); // Set the devisi list

			}
		} catch (error) {
			console.error('Error fetching devisi:', error);
		}
	};

	const handleSubmitPendidikan = async (e) => {
		e.preventDefault();
		try {
			for (const Pendidikan of riwayatPendidikan) {
				if (Pendidikan.pendidikanID) {
					await axios.patch(`api/pendidikan/${Pendidikan.pendidikanID}`, Pendidikan, header);
				} else {
					await axios.post(`api/pendidikan`, { ...Pendidikan, userID }, header);
					fetchPendidikan()
				}
			}
			Swal.fire({
				icon: "success",
				title: "Riwayat Pendidikan Tersimpan",
				showConfirmButton: true,
				timer: 1000

			});
		} catch (error) {
			console.error('Error updating pendidikan data:', error);
		}
	};

	const handleAddPendidikan = () => {
		setRiwayatPendidikan([...riwayatPendidikan, { namaInstitusi: '', jurusan: '', jenjang: '', tahunLulus: '' }]);
	};

	const handleRemovePendidikan = async (index, pendidikanID) => {
		const result = await Swal.fire({
			title: 'Apakah Anda yakin?',
			text: "Data pendidikan ini akan dihapus!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Ya, hapus!',
			cancelButtonText: 'Batal'
		});

		if (result.isConfirmed) {
			if (pendidikanID) {
				try {
					await axios.delete(`api/pendidikan/${pendidikanID}`, header);
					setSnackbarOpen(true);
				} catch (error) {
					console.error('Error deleting pendidikan data:', error);
				}
			}
			const newPendidikan = riwayatPendidikan.filter((_, i) => i !== index);
			setRiwayatPendidikan(newPendidikan);
		}
	};

	const handleChangePendidikan = (index, e) => {
		const { name, value } = e.target;
		const newPendidikan = [...riwayatPendidikan];
		newPendidikan[index][name] = value;
		setRiwayatPendidikan(newPendidikan);
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	return (
		<>
			<form onSubmit={handleSubmitPendidikan} className="">
				<div className='p-2'>
					<h2 className="text-xl font-bold mb-4">Riwayat Pendidikan</h2>
					<div className='border p-4 mb-4 rounded-md'>
						{riwayatPendidikan.map((item, i) => {
							return (
								(
									<div key={i} className="flex items-end">
										<div className='w-full mx-1'>
											<label className="flex text-sm font-medium text-gray-700">Nama Institusi:</label>
											<input type="text" placeholder=' Nama Instansi' name="namaInstitusi" value={item.namaInstitusi || ''} onChange={(e) => handleChangePendidikan(i, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
										</div>
										<div className='w-1/2 mx-1'>
											<label className="flex text-sm font-medium text-gray-700">Jurusan:</label>
											<input type="text" placeholder='Jurusan' name="jurusan" value={item.jurusan} onChange={(e) => handleChangePendidikan(i, e)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
										</div>
										<div className='w-1/4 mx-1'>
											<label className="flex text-sm font-medium text-gray-700">Jenjang:</label>
											<input type="text" placeholder='Jenjang' name="jenjang" value={item.jenjang} onChange={(e) => handleChangePendidikan(i, e)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
										</div>
										<div className='w-1/4 mx-1'>
											<label className="flex text-sm font-medium text-gray-700">Tahun Lulus:</label>
											<input type="number" placeholder=' Tahun Lulus' name="tahunLulus" value={item.tahunLulus} onChange={(e) => handleChangePendidikan(i, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
										</div>
										<button type="button" onClick={() => handleRemovePendidikan(i, item.pendidikanID)} className="py-2 bg-red-500 w-28 h-10 text-white flex justify-center items-center">
											<FaTrashAlt />
										</button>
									</div>
								)
							)
						})}
						<div className='w-full flex justify-end'>
							<button type="button" onClick={handleAddPendidikan} className="mt-4  text-[#7c414c] font-semibold py-2 px-3 rounded-md border border-[#7c414c]">+ Tambah Pendidikan</button>
						</div>
					</div>
				</div>
				<button disabled={riwayatPendidikan.length === 0} type="submit" className=" disabled:bg-[#86b0c0] disabled:text-gray-300 disabled:cursor-not-allowed mt-4 w-full bg-[#31525e] text-white font-semibold py-2 rounded-md flex  justify-center items-center space-x-2 hover:bg-[#4e7685]"><FaSave /> <span>Simpan</span></button>
			</form>
			<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
				<MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
					Data pendidikan berhasil dihapus!
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default EducationDataForm;
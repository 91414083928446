
import { Route, Routes } from "react-router-dom";

import Header from "../component/Home/Header";
import Footer from "../component/Home/Footer";
import Home from "../page/Home/Home";
import Karyawan from "../page/Home/Karyawan/Karyawan";
import KaryawanList from "../page/Home/Karyawan/Karyawan.list";
import Users from "../page/Home/Users/Users";
import ScrollToTop from "../component/ScrollToTop";
import Dosen from "../page/Home/Dosen/Dosen";
import DosenList from "../page/Home/Dosen/Dosen.list";

const HomeLayout = () => {

    return (
        <>
            <Header />
            <ScrollToTop />
            <Routes >
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/pegawai" element={<Karyawan />} />
                <Route path="/dosen" element={<Dosen />} />
                <Route path="/fakultas/:fakultasID" element={<DosenList />} />
                <Route path="/pegawai/:devisiID" element={<KaryawanList />} />
                <Route path="/user/:userID" element={<Users />} />
            </Routes>
            <Footer />
        </>
    );
}

export default HomeLayout;


import { useState } from 'react';
import { FaThLarge, FaUserCircle, FaSignOutAlt, FaUser, FaTimes } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function NavbarAdmin({ showSidebar, setShowSidebar, data }) {

    const location = useLocation().pathname;
    const [flyer, setFlyer] = useState(false);

    // Fungsi untuk membuat breadcrumb dinamis
    const createBreadcrumbs = () => {
        const pathnames = location.split('/').filter(x => x);
        return pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            return (
                <li key={to} className='inline-flex font-thin items-center'>
                    <NavLink to={to} className="text-gray-300 md:text-gray-500 hover:text-gray-900 flex items-center">
                        <span>{value}</span>
                    </NavLink>
                    {index < pathnames.length - 1 && (
                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                        </svg>
                    )}
                </li>
            );
        });
    };
    const logout = () => {
        Cookies.remove('token');
    };
    return (
        <>
            <nav className=" md:bg-white bg-[#51242c] shadow-lg md:ml-64 py-3 md:py-4 px-3">
                <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 ">
                    <div className="md:block hidden  ">

                        <button onClick={() => setShowSidebar('-left-64')} className=" text-lg hover:text-gray-200 py-2 px-4 bg-white rounded">
                            <FaThLarge />
                        </button>

                    </div>
                    <div className="md:hidden mr-3  ">
                        <button onClick={() => setShowSidebar('left-0')}>
                        </button>
                        <button onClick={() => setShowSidebar('left-0')} className=" text-lg hover:text-gray-200 py-2 px-4 bg-white rounded">
                            <FaThLarge />
                        </button>
                        <div
                            className={`absolute top-4 md:hidden ${showSidebar === 'left-0' ? 'left-52' : '-left-52'
                                } z-50 transition-all duration-300`}>
                            <button className=" text-lg text-white py-2 px-2 border rounded"
                                onClick={() => setShowSidebar('-left-64')}>
                                <FaTimes />
                            </button>
                        </div>
                    </div>
                    <div className="flex  justify-between items-center w-full">
                        <div className="  text-gray-500 text-md tracking-wider ">
                            <ol id='breadcrumb' className="inline-flex items-center ">
                                {createBreadcrumbs()}
                            </ol>
                        </div>
                        <div className="flex text-gray-500">
                            <div className="relative">
                                <button type="button" className="grou rounded-md md:text-gray-600 text-white inline-flex items-center text-base font-sm  focus:outline-none  active:outline-none focus:ring-offset-2 focus:ring-indigo-500 pb-8'" onClick={() => (setFlyer(!flyer))}>
                                    <div className='relative text-[#51242c] text-2xl '>
                                        <FaUserCircle />
                                    </div>
                                    <span className=' hover:text-gray-500 ml-2'> {data.user.nama}</span>
                                    <svg className={flyer === true
                                        ? "transform rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-out duration-200"
                                        : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                {/* onMouseLeave={() => setFlyer(false)} */}
                                <div onMouseLeave={() => setFlyer(false)} className={flyer ? "  opacity-100 translate-y-2 translate-x-[-63%] transition ease-out duration-200 absolute z-20  -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0  md:-translate-x-[70%]" : " opacity-0 translate-y-1 absolute z-[-1] -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 translate-x-[-63%] md:-translate-x-[70%]"}  >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="shadow-md bg-gray-50">

                                            <div className="flow-root">
                                                <div className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                                                    <div className="flex w-full justify-center items-center ">
                                                        <div className="w-full">


                                                            <div className="relative flex flex-col  bg-white shadow-md w-full rounded-xl bg-clip-border">
                                                                <div className="relative py-8 grid mx-4 mb-4 mt-6 overflow-hidden text-white shadow-lg place-items-center rounded-xl bg-gradient-to-tr from-gray-900 to-gray-800 bg-clip-border shadow-gray-900/20">
                                                                    <span className=' text-5xl'>
                                                                        <FaUserCircle />
                                                                    </span>
                                                                    <div className="block font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-white">
                                                                        {data.user.nama}
                                                                    </div>
                                                                </div>
                                                                <div className="mb-4 w-full min-w-[200px]">
                                                                    <div className="px-4 ">
                                                                        <ul className="divide-y divide-gray-200">
                                                                            <li className="p-2 flex justify-between items-center user-card">
                                                                                <div className="flex items-center">
                                                                                    <FaUser />
                                                                                    <span className="ml-3 font-medium">Profile</span>
                                                                                </div>
                                                                                <div>
                                                                                    <button className="text-gray-500 hover:text-gray-700">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                            <path d="M4 6h16M4 12h16m-7 6h7" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </li>
                                                                            {/* <li className="p-3 flex justify-between items-center user-card">
                                                                                <div className="flex items-center">
                                                                                    <FaKey />
                                                                                    <span className="ml-3 font-medium">Ganti Password</span>
                                                                                </div>
                                                                                <div>
                                                                                    <button className="text-gray-500 hover:text-gray-700">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                            <path  d="M4 6h16M4 12h16m-7 6h7" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </li> */}


                                                                        </ul>
                                                                    </div>
                                                                </div>


                                                                <div className="p-6 pt-0">
                                                                    <button
                                                                        onClick={logout}
                                                                        className=" flex justify-center items-center space-x-2 w-full select-none rounded-lg bg-[#51242c] py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                                                        type="button">
                                                                        <span className='text-xl'>
                                                                            <FaSignOutAlt />
                                                                        </span>
                                                                        <span>Log Out</span>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div >
            </nav >



            {/* This example requires Tailwind CSS v2.0+ */}

        </>
    );
}

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const PegawaiAdd = ({ data }) => {
    const header = {
        headers: { 'Authorization': `Bearer ${data.token}` }
    }
    const [formDataPribadi, setFormDataPribadi] = useState({});

    const navigate = useNavigate(); // Inisialisasi useNavigate



    const handleChangeDataPribadi = (e) => {
        const { name, value } = e.target;
        setFormDataPribadi({ ...formDataPribadi, [name]: value || '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let save = await axios.post(`api/user`, formDataPribadi, header);
            Swal.fire({
                icon: "success",
                title: "Tersimpan",
                showConfirmButton: false,
                timer: 1500
            });
            const ID = save.data.userID;

            navigate(`/admin/pegawai/${ID}`, { state: { activeTab: "formDataPendidikan" } });
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };


    return (
        <div className='p-6 bg-white rounded-lg shadow-md'>
            <div id='tabs' className=" uppercase flex w-full  mb-4">
                <div

                    className={` w-full font-bold py-2 px-4  bg-[#51242c] text-white`}
                >
                    Data Pribadi
                </div>
                <div

                    className={` cursor-not-allowed w-full font-bold py-2 px-4 bg-gray-200 text-gray-700 hover:bg-gray-300`}
                >
                    Data Pendidikan
                </div>
                <div

                    className={` cursor-not-allowed w-full font-bold py-2 px-4 bg-gray-200 text-gray-700 hover:bg-gray-300`}
                >
                    Data Kepegawaian
                </div>
            </div>

            <form onSubmit={handleSubmit} className="">
                <h2 className="text-xl font-bold mb-4">Data Pribadi</h2>
                <div className=' grid grid-cols-2'>
                    <div className=' p-2'>
                        <div className="mb-4">
                            <label className="flex text-sm font-medium text-gray-700">Nama:</label>
                            <input type="text" name="nama" value={formDataPribadi.nama || ''} onChange={handleChangeDataPribadi} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
                        </div>
                        <div className="mb-4">
                            <label className="flex text-sm font-medium text-gray-700">Email:</label>
                            <input type="email" name="email" value={formDataPribadi.email || ''} onChange={handleChangeDataPribadi} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
                        </div>
                        <div className="mb-4">
                            <label className="flex text-sm font-medium text-gray-700">Jenis Kelamin:</label>
                            <select name="jenisKelamin" value={formDataPribadi.jenisKelamin || ''} onChange={handleChangeDataPribadi} className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
                                <option value="">Pilih</option>
                                <option value="L">Laki-laki</option>
                                <option value="P">Perempuan</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="flex text-sm font-medium text-gray-700">Tanggal Lahir:</label>
                            <input type="date" name="tanggalLahir" value={formDataPribadi.tanggalLahir || ''} onChange={handleChangeDataPribadi} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
                        </div>
                    </div>
                    <div className=' p-2'>
                        <div className="mb-4">
                            <label className="flex text-sm font-medium text-gray-700">Telepon:</label>
                            <input type="tel" name="telepon" value={formDataPribadi.telepon || ''} onChange={handleChangeDataPribadi} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
                        </div>
                        <div className="mb-4">
                            <label className="flex text-sm font-medium text-gray-700">Alamat:</label>
                            <textarea name="alamat" value={formDataPribadi.alamat || ''} onChange={handleChangeDataPribadi} className="mt-1 block w-full p-2 border border-gray-300 rounded-md"></textarea>
                        </div>

                    </div>
                </div>
                <button type="submit" className="mt-4 w-full bg-[#31525e] text-white font-semibold py-2 rounded-md hover:bg-[#567c89]">Selanjutnya</button>
            </form>


        </div>
    );
};

export default PegawaiAdd;
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Menu from "../../json/menu_admin.json";
import * as Icons from 'react-icons/fa'; // Mengimpor semua ikon dari react-icons/fa
import { NavLink } from 'react-router-dom';

const NestedMenu = ({ menu, setShowSidebar }) => {  // Pastikan setShowSidebar diteruskan ke sini
    const [openIndex, setOpenIndex] = useState(null);

    const toggleSubMenu = (index, hasPath) => {
        if (hasPath) {
            setShowSidebar('-left-64');
        }
        setOpenIndex(openIndex === index ? null : index);
    };
    const renderIcon = (iconStr) => {
        const IconComponent = Icons[iconStr];
        return IconComponent ? <IconComponent /> : null;
    };
    return (
        <ul className="text-white font-light">
            {menu.map((item, index) => (
                <li key={index} className="pl-3">
                    {item.path ? (
                        <NavLink
                            to={`${item.path}`}
                            className="flex justify-between rounded-md py-2 px-3 hover:bg-[#5d2f37] items-center gap-2 cursor-pointer"
                            onClick={() => toggleSubMenu(index, true)}
                        >
                            <div className="flex items-center gap-2">
                                <span className="text-xl">
                                    {renderIcon(item.icon)}
                                </span>
                                <span>{item.name}</span>
                            </div>
                            {item.sub.length > 0 ? (<Icons.FaChevronDown />) : ""}
                        </NavLink>
                    ) : (
                        <div
                            className="flex justify-between rounded-md py-2 px-3 hover:bg-[#5d2f37] items-center gap-2 cursor-pointer"
                            onClick={() => toggleSubMenu(index, false)}
                        >
                            <div className="flex items-center gap-2">
                                <span className="text-xl">
                                    {renderIcon(item.icon)}
                                </span>
                                <span>{item.name}</span>
                            </div>
                            {item.sub.length > 0 ? (<Icons.FaChevronDown />) : ""}
                        </div>
                    )}

                    <AnimatePresence>
                        {item.sub && item.sub.length > 0 && openIndex === index && (
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: "auto", opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                className="overflow-hidden"
                            >
                                <NestedMenu menu={item.sub} setShowSidebar={setShowSidebar} />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </li>
            ))}
        </ul>
    );
};

const Menus = ({ setShowSidebar }) => {
    return <NestedMenu menu={Menu} setShowSidebar={setShowSidebar} />;
};

export default Menus;

import { useState } from 'react';
import logo from "../../assets/img/unes-small.png";
// import Menu from "../../json/menu_admin.json";
import NavbarAdmin from './NavbarAdmin';
import * as Icons from 'react-icons/fa'; // Mengimpor semua ikon dari react-icons/fa
import Cookies from 'js-cookie';
import Menus from './Menus';

const SidebarAdmin = ({ data }) => {
    const [showSidebar, setShowSidebar] = useState('-left-64');

    const renderIcon = (iconStr) => {
        const IconComponent = Icons[iconStr];
        return IconComponent ? <IconComponent /> : null;
    };

    const logout = () => {
        Cookies.remove('token');
    };

    return (
        <>
            <NavbarAdmin
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                data={data}
            />
            <div className=' z-10'>
                <div onMouseLeave={() => setShowSidebar('-left-64')} className={`h-screen bg-[#51242c]  fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl  w-64 z-10 py-4  transition-all duration-300`}>
                    <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                        <div className=" text-center w-full inline-block">
                            <div className=' flex w-full justify-center'>
                                <img className='p-2' src={logo} width={120} alt="logo" />
                            </div>
                            <div className=' text-white uppercase font-bold' >SIMPEG</div>
                        </div>
                        <div className="flex flex-col">
                            <hr className="my-4 min-w-full" />
                            <Menus setShowSidebar={setShowSidebar} />
                            <hr className="my-4 min-w-full" />
                            <ul className="flex-col min-w-full flex list-none">
                                <div className="rounded-lg hover:bg-[#5d2f37] hover:text-white text-white bg-[#51242c] ">
                                    <li onClick={logout} className=" cursor-pointer flex items-center gap-4 text-sm font-light px-6 py-3 rounded-lg" >
                                        <span className='text-xl'> {renderIcon('FaSignOutAlt')}</span>
                                        Log Out
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SidebarAdmin
import { NavLink } from 'react-router-dom';

const Breadcrumb = ({ paths }) => {
	return (
		<div id="Breadcrumb" className='md:mt-20 mt-16 flex justify-center items-center h-20 w-full bg-gray-50'>
			<nav className="flex bg-gray-50 mt-2 container mx-auto text-gray-700  py-3 px-5 rounded-lg" aria-label="Breadcrumb">
				<ol className="inline-flex text-xs md:text-sm items-center space-x-1 md:space-x-3">


					{paths.map((path, index) => (
						<li key={index} className="inline-flex items-center">
							{index === 0 && (
								<svg className="w-4 h-4 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>

							)}
							{index > 0 && (
								<svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
								</svg>
							)}

							{path.link ? (
								<NavLink to={path.link} className=" ml-2 text-gray-500 hover:text-gray-900 flex items-center">
									<span>{path.name}</span>
								</NavLink>

							) :
								(
									<div className=" ml-2 text-gray-500 hover:text-gray-900 flex items-center">
										<span>{path.name}</span>
									</div>

								)}
						</li>
					))}
				</ol>
			</nav>
		</div>
	);
}

export default Breadcrumb;
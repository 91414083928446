import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = 'http://localhost:3002/'
} else {
  axios.defaults.baseURL = 'https://simpeg.unespadang.ac.id/'
}

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
// serviceWorkerRegistration.register();

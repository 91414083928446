import logo from "../assets/img/logo.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import md5 from "md5";
import axios from "axios";
import { isAuth } from "../helper/auth";
import swal from "sweetalert";
import banner from "../assets/img/slide1.png"
import { useCookies } from 'react-cookie';
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
function Login() {
    const [cookies, setCookie] = useCookies(['token']);
    const user = isAuth(cookies.token)
    const nav = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        if (user) {
            const role = user.role; // Get the user's role
            if (role === "admin") {
                nav("/admin"); // Redirect to admin page
            } else if (role === "staff") {
                nav("/dashboard"); // Redirect to staff dashboard
            }
        }
    }, [user, nav]); // Add dependencies

    const loginHandler = (e) => {
        e.preventDefault();
        axios.post('api/auth/login', {
            'username': username,
            'password': md5(password)
        }, {
            withCredentials: false, headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }).then((response) => {
            if (response.data.user?.role === "admin") {
                setCookie('token', response.data.token, { path: '/', secure: true });
                nav("/admin");
            } else if (response.data.user?.role === "staff") {
                setCookie('token', response.data.token, { path: '/', secure: true });
                nav("/dashboard");
            }
        }).catch((error) => {
            swal("Gagal", "Username atau Password salah!", "error");
        })
    }
    return (
        <>
            <div className="flex  h-screen justify-center  overflow-hidden items-center">
                <div className=" w-full px-6 md:px-6 md:w-2/3">

                    <div className=" w-full flex justify-center shadow-lg ">
                        <div className=" w-full md:h-[400px] md:flex">
                            <div
                                className="relative rounded-l-md overflow-hidden md:flex w-1/2 bg-[#51242c] justify-around items-center hidden">
                                <div className="static">
                                    <div className=" pb-5 flex justify-center w-full">
                                        <img className=" drop-shadow-lg w-1/2" src={banner} alt="" />
                                    </div>


                                    {/* <div>
                                            <h1 className="text-white font-bold text-2xl font-sans">UNIVERSITAS EKASAKTI</h1>
                                        </div> */}
                                    <div>
                                        <p className="text-white font-bold text-xl uppercase  ">SIMPEG</p>
                                        <p className="text-gray-200 font-thin text-xl   ">Sistem Informasi Kepegawaian</p>
                                    </div>

                                    <div className="absolute leading-4 bottom-5 font-extralight left-0 right-0 text-gray-400 ">
                                        <div className=" ">
                                            <small>
                                                Powered By Universitas Ekasakti
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" md:rounded-r-md rounded-2xl flex md:w-1/2 w-full justify-center py-6 items-center  bg-white">
                                <form onSubmit={loginHandler} className=" w-full px-8 md:px-0 md:w-2/3 ">
                                    <img className=" mx-auto  drop-shadow-lg  mb-2 w-32 py-2 " src={logo} alt="" />
                                    <div className="md:hidden block text-gray-700">
                                        <p className=" font-bold text-xl uppercase  ">SIMPEG</p>
                                        <p className=" font-thin text-xl   ">Sistem Informasi Kepegawaian</p>
                                    </div>
                                    <h1 className="text-gray-500 m-4 font-bold md:text-2xl text-1xl  ">Login Administrator</h1>

                                    <div className="flex items-center border py-2 bg-white px-3 rounded-lg mb-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-[#51242c]" viewBox="0 0 20 20"
                                            fill="currentColor">
                                            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                clipRule="evenodd" />
                                        </svg>
                                        <input value={username} onChange={(e) => setUsername(e.target.value)} className="pl-2 w-full font-extralight outline-none border-none" type="text" required placeholder="Username" />
                                    </div>


                                    <div className="flex items-center border py-2 bg-white px-3 rounded-lg">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-[#51242c]" viewBox="0 0 20 20"
                                            fill="currentColor">
                                            <path fillRule="evenodd"
                                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                                clipRule="evenodd" />
                                        </svg>
                                        <input className="pl-2 w-full font-extralight outline-none border-none"
                                            type={passwordVisible ? "text" : "password"}
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password" />
                                        <button type="button" className=" ml-2" onClick={() => setPasswordVisible(!passwordVisible)}>
                                            {passwordVisible ? (<FaEye />) : (<FaRegEyeSlash />)}
                                        </button>
                                    </div>
                                    <button type="submit" onClick={loginHandler} className="block w-full bg-[#51242c] mt-4 py-2 rounded-2xl text-white font-semibold mb-2">Login</button>
                                    {/* <span className="text-sm ml-2 hover:text-blue-500 cursor-pointer">Forgot Password ?</span> */}
                                </form>
                            </div>
                        </div >

                    </div>
                </div>
            </div>
        </>




















    );
}

export default Login;
import { useState, useEffect } from 'react';

import axios from 'axios';
import Pagination from '../../component/Admin/Pagination';
import { FaEdit, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Pegawai = ({ data }) => {

    const header = {
        headers: { 'Authorization': `Bearer ${data.token}` }
    }
    const [listUsers, setListUsers] = useState([]);



    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectValue, setSelectValue] = useState(false);


    useEffect(() => {
        getListUsers();
        // eslint-disable-next-line
    }, [page, limit]);

    const getListUsers = () => {
        axios.get(`api/users?page=${page}&limit=${limit}&kepegawaian=${selectValue}`, header)
            .then((response) => {
                setMeta(response.data.meta);
                setListUsers(response.data.items);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };





    const selectPage = (value) => {
        setPage(value);
    };

    const nextPage = () => {
        if (page < meta.totalPages) {
            setPage(page + 1);
        }
    };

    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const limitPage = (event) => {
        setLimit(Number(event.target.value));
    };

    const handleSelectChange = (event) => {

        setSelectValue(event.target.value)
        // Hit API untuk mendapatkan pengguna berdasarkan kepegawaian yang dipilih
        axios.get(`api/users?page=${page}&limit=${limit}&kepegawaian=${event.target.value}`, header)
            .then((response) => {
                setPage(1)
                setMeta(response.data.meta);
                setListUsers(response.data.items);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSearchChange = (event) => {
        const searchValue = event.target.value;
        // Hit API untuk mencari pengguna berdasarkan input pencarian
        axios.get(`api/users?search=${searchValue}&page=${page}&limit=${limit}`, header)
            .then((response) => {
                setMeta(response.data.meta);
                setListUsers(response.data.items);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const handleClickDelete = async (userID) => {
        const result = await Swal.fire({
            title: 'Apakah Anda yakin?',
            text: "Anda tidak dapat mengembalikan ini!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya, hapus!'
        });

        if (result.isConfirmed) {
            await axios.delete(`api/users/${userID}`, header)
                .then((response) => {
                    console.log('User deleted successfully:', response);
                    // Update the list of users after deletion
                    setListUsers(listUsers.filter(item => item.userID !== userID));
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    console.error('Error deleting user:', error);
                });
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };




    return (
        <>

            <div className="flex my-4 md:px-0 bg-white   flex-col">
                <div className=" m-6 ">
                    <NavLink to={'add'}>

                        <button className=' flex  items-center px-4 h-12  text-white bg-[#51242c]' type="button"><span className='p-1'><FaPlusCircle /></span> TAMBAH PEGAWAI</button>
                    </NavLink>
                </div>
            </div >
            <div className="flex p-4 md:px-0 bg-white  flex-col">
                <div className='px-8 items-center  flex justify-between w-full '>
                    <div>
                        <select id="pilih_pegawai" onChange={handleSelectChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded outline-none block w-full p-3 ">
                            <option >Filter Kepegawaian</option>
                            <option value="karyawan">Karyawan</option>
                            <option value="dosen">Dosen</option>
                        </select>
                    </div>
                    <div className="flex md:ml-2 px-4 py-3 rounded-md border text-gray-500 border-gray-200  max-w-md  font-[sans-serif]">
                        <svg viewBox="0 0 192.904 192.904" width="16px"
                            className=" mr-3 rotate-90">
                            <path
                                d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z">
                            </path>
                        </svg>
                        <input id='cari_pengguna' onChange={handleSearchChange} type="text" placeholder="Cari Pengguna..." className="w-full outline-none bg-transparent text-gray-600 text-sm" />
                    </div>
                </div>
                <div className="overflow-x-auto  md:m-6 m-0">
                    <div className="inline-block min-w-full ">
                        <div className="overflow-hidden ">
                            <table className="border min-w-full">
                                <thead className="bg-[#31525e] border-b">
                                    <tr>
                                        <th scope="col" className=" uppercase text-xs  font-bold text-white pl-4 py-2 text-center">No.</th>
                                        <th scope="col" className=" uppercase text-xs font-bold text-white px-2 py-2 text-left">Nama</th>
                                        <th scope="col" className=" uppercase text-xs font-bold text-white px-2 py-2 text-left">email</th>
                                        <th scope="col" className=" uppercase text-xs font-bold text-white px-2 py-2 text-center">Kepegawaian</th>
                                        <th scope="col" className=" uppercase text-xs font-bold text-white px-2 py-2 text-center">Action </th>
                                        {/* <th scope="col" className=" uppercase text-xs font-bold text-white px-2 py-2 text-center">EDIT </th>
                                        <th scope="col" className=" uppercase text-xs font-bold text-white px-2 py-2 text-center">HAPUS</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {listUsers.length > 0 ? (
                                        listUsers.map((item, index) => (
                                            <tr key={index} className={` hover:bg-gray-50  border-b bg-white`}>
                                                <td className="text-xs font-medium text-gray-900">{(page - 1) * limit + index + 1}</td>
                                                <td className="text-xs text-gray-900  px-2 py-2 whitespace-nowrap font-semibold text-left">{item.nama}</td>
                                                <td className="text-xs text-gray-900  px-2 py-2  whitespace-nowrap text-left">{item.email}</td>
                                                {/* <td className="text-xs text-gray-900  px-2 py-2 whitespace-nowrap text-left">{item.alamat}</td> */}
                                                <td className="text-xs text-gray-900  whitespace-nowrap text-center">
                                                    <div className=' flex text-white '>
                                                        {item.karyawan && (<div className=' text-xs px-3 mx-1 py-2 rounded-full bg-green-600'>Karyawan ({item.karyawan.devisi?.nama_devisi})</div>)}
                                                        {item.dosen && (<div className='text-xs px-3 py-2 mx-1 rounded-full bg-blue-600'>Dosen</div>)}
                                                    </div>
                                                </td>
                                                <td className=' flex justify-center'>
                                                    <div className='flex p-1 w-12 justify-center items-center'>
                                                        <NavLink to={`/admin/pegawai/${item.userID}`} className={`shadow  rounded p-2  text-white  cursor-pointer bg-[#2c83b5] hover:bg-[#70c6f7]`}>
                                                            <FaEdit />
                                                        </NavLink>
                                                    </div>
                                                    <div className={`flex p-1 w-12 justify-center items-center `}
                                                        onClick={() => handleClickDelete(item.userID)}>
                                                        <span className={`shadow rounded p-2  text-white  cursor-pointer bg-[#ad2626] hover:bg-[#ea7676]`}>
                                                            <FaTrashAlt />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="bg-white border-b">

                                            <td colSpan={8} className="px-6 py-4 whitespace-nowrap text-2xl font-medium text-gray-300">
                                                <div className='flex justify-center'>
                                                    <div className=' items-center text-center'>
                                                        Data Tidak Ditemukan
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Pagination
                    currentPage={page}
                    totalPages={meta.totalPages}
                    limitPage={limitPage}
                    selectPage={selectPage}
                    nextPage={nextPage}
                    prevPage={prevPage}
                />
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Pengguna berhasil dihapus!
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default Pegawai;
import slide1 from "./../../assets/img/slide1.png";
// import slide2 from "./../../assets/img/slide2.png";
// import slide3 from "./../../assets/img/slide3.png";
// import slide4 from "./../../assets/img/slide4.png";
// import slide5 from "./../../assets/img/slide5.png";
import tutwiri from "./../../assets/img/Tutwuri-Handayani.png";
import unes_s from "./../../assets/img/unes-small.png";

import kampus_merdeka from "./../../assets/img/kampus-merdeka.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Home = () => {

    const settings = {
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        // adaptiveHeight: true,
        pauseOnHover: false,
        // variableWidth: true


    };



    return (
        <>
            {/* <HomeLayout> */}
            <div className=" mt-16 md:grid md:grid-cols-2 md:gap-2 grid-cols-none   w-full relative bg-white">
                <div className="flex bg-white flex-col justify-center items-start   w-full h-full md:h-[720px] gap-2.5 md:pl-10 lg:pl-20 md:py-32 py-12">
                    <div className="flex flex-col justify-start items-start   w-full pl-12">
                        <div className="flex flex-col justify-start items-start self-stretch   md:h-[269px] h-[100px] ">
                            <div className="flex flex-col justify-center items-start   h-[150px] md:h-[206px] w-full relative gap-2">
                                <p className="self-stretch   w-full text-3xl lg:text-[50px] font-bold text-left text-slate-900">
                                    SELAMAT DATANG
                                </p>
                                <p className="w-full md:text-2xl text-left text-black">
                                    di Sistem informasi pegawai (SIMPEG)
                                </p>
                                <p className=" w-full leading-none  text-xl text-left text-slate-700">
                                    Universitas Ekasakti
                                </p>
                                <div className="md:grid grid-cols-none md:grid-cols-3 md:gap-3">

                                    <div className='  flex justify-start py-2 w-full items-center'>
                                        <img src={tutwiri} className=" p-1  w-[37.19px] h-[36.5px] object-contain" alt=' tutwuri' />
                                        <img src={unes_s} className=" p-1  w-9 h-[38px] object-contain" alt='unes' />
                                        <img src={kampus_merdeka} className=" p-1  w-[66px] h-[35.32px] object-contain" alt='kampus merdeka' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex md:mt-26 flex-col justify-center items-center w-full h-full md:h-[720px] relative overflow-hidden">
                    <div className="w-full   justify-center items-center ">
                        <Slider {...settings}>
                            <img src={slide1} className="  object-contain" alt='slide1' />
                            {/* <img src={slide2} className="  object-contain" alt='slide2' /> */}
                            {/* <img src={slide3} className="  object-contain" alt='slide3' />
                            <img src={slide4} className="  object-contain" alt='slide4' />
                            <img src={slide5} className="  object-contain" alt='slide5' /> */}
                        </Slider>
                    </div>
                </div>
            </div>


            <div id='about' className=' flex mb-8 justify-center '>
                <div className="flex mt-8 md:mx-8 mx-4 flex-col justify-start items-center w-full h-full relative  bg-white" style={{ boxShadow: "0px 4px 4px 0 rgba(0,0,0,0.25)" }}>
                    <div className="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
                        <div className="w-full lg:w-1/2">
                            <div className="lg:max-w-lg">
                                <h1 className="text-2xl text-left uppercase font-bold tracking-wide text-gray-800  lg:text-4xl">
                                    Sistem Informasi Kepegawaian (SIMPEG)
                                </h1>

                                <div className="mt-8 md:text-left space-y-5">
                                    Sistem Informasi Kepegawaian (SIMPEG) Universitas Ekesakti dirancang untuk memudahkan pengelolaan data kepegawaian dan dosen secara efektif dan efisien.
                                    Dengan SIMPEG, Anda dapat mengakses informasi pegawai, riwayat jabatan, data serta berbagai fitur lainnya yang mendukung administrasi kepegawaian.
                                </div>
                            </div>


                        </div>

                        <div className="flex items-center justify-center w-full h-96 lg:w-1/2">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/6jbeuT6NZJ0?si=WzctqwbSy3w7OJop" ></iframe>
                        </div>
                    </div>
                </div>
            </div>




            {/* </HomeLayout> */}

        </>
    )
}

export default Home

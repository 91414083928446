import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaExclamationTriangle, FaTrashAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const EmployeeDataForm = ({ header }) => {
	const { userID } = useParams();

	const [formDataPegawaiDosen, setFormDataPegawaiDosen] = useState();
	const [formDataPegawaiKaryawan, setFormDataPegawaiKaryawan] = useState();
	const [devisiList, setDevisiList] = useState([]); // State for devisi list
	const [fakultasList, setFakultasList] = useState([]); // State for devisi list
	const [activeCheck, setActiveCheck] = useState(); // State for active tab
	const [activeTab, setActiveTab] = useState('data'); // State for active tab in Dosen form

	// State untuk riwayat penelitian, pengabdian, dan publikasi
	const [riwayatPenelitian, setRiwayatPenelitian] = useState();
	const [riwayatPengabdian, setRiwayatPengabdian] = useState();
	const [riwayatPublikasi, setRiwayatPublikasi] = useState();

	const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
	const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
	useEffect(() => {

		const fetchDevisi = async () => { // New function to fetch devisi
			try {
				const response = await axios.get('api/devisi'); // Adjust the API endpoint as needed
				setDevisiList(response.data); // Set the devisi list
			} catch (error) {
				console.error('Error fetching devisi:', error);
			}
		};
		const fetchFakultas = async () => { // New function to fetch devisi
			try {
				const response = await axios.get('api/fakultas'); // Adjust the API endpoint as needed
				setFakultasList(response.data); // Set the devisi list
			} catch (error) {
				console.error('Error fetching devisi:', error);
			}
		};
		fetchDevisi()
		fetchFakultas()
		fetchDosen()
		fetchKaryawan()
		// eslint-disable-next-line
	}, [userID])
	const fetchDosen = async () => { // New function to fetch devisi
		try {
			const response = await axios.get(`api/dosen/${userID}`, header); // Adjust the API endpoint as needed
			if (response.data) {
				setFormDataPegawaiDosen(response.data); // Set the devisi list
				setRiwayatPenelitian(response.data.penelitian)
				setRiwayatPengabdian(response.data.pengabdian)
				setRiwayatPublikasi(response.data.publikasi)
				setActiveCheck('dosen')
			}
		} catch (error) {
			console.error('Error fetching devisi:', error);
		}
	};
	const fetchKaryawan = async () => { // New function to fetch devisi
		try {
			const response = await axios.get(`api/karyawan/${userID}`, header); // Adjust the API endpoint as needed
			if (response.data) {
				setFormDataPegawaiKaryawan(response.data); // Set the devisi list
				setActiveCheck('karyawan')
			}

		} catch (error) {
			console.error('Error fetching devisi:', error);
		}
	};
	const handleChangeDataPegawai = (e) => {
		const { name, value } = e.target;
		if (activeCheck === 'dosen') {
			setFormDataPegawaiDosen({ ...formDataPegawaiDosen, [name]: value || '' });
		} else if (activeCheck === 'karyawan') {
			setFormDataPegawaiKaryawan({ ...formDataPegawaiKaryawan, [name]: value || '' });
		}
	};

	// Fungsi untuk menambah item
	const handleAddItem = (setItem, item) => {
		setItem(prev => [...prev, item]);
	};

	// Fungsi untuk menghapus item
	const handleRemoveItem = async (setItem, index, itemId, apiEndpoint) => {
		// Menghapus item dari state
		const result = await Swal.fire({
			title: 'Apakah Anda yakin?',
			text: "Item ini akan dihapus!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Ya, hapus!'
		});

		if (result.isConfirmed) {
			setItem(prev => prev.filter((_, i) => i !== index)); // Menggunakan index untuk menghapus dari state

			// Menghapus item dari API
			try {
				await axios.delete(`${apiEndpoint}/${itemId}`, header); // Menggunakan itemId untuk menghapus dari API
				setSnackbarMessage("Item ini berhasi dihapus!"); // Set Snackbar message
				setSnackbarOpen(true); // Open Snackbar
			} catch (error) {
				console.error('Error deleting item:', error);
			}
		}
	};

	// Fungsi untuk mengubah item
	const handleChangeItem = (setItem, index, e) => {
		const { name, value } = e.target;
		setItem(prev => {
			const newItems = [...prev];
			newItems[index][name] = value;
			return newItems;
		});
	};

	const handleSubmitDosen = async (e) => {
		e.preventDefault();
		try {
			await axios.post(`api/dosen`, { ...formDataPegawaiDosen, userID }, header);
			fetchDosen()
			Swal.fire({
				icon: "success",
				title: "Tersimpan",
				showConfirmButton: true,
				timer: 1000
			});
		} catch (error) {
			console.error('Error updating Dosen data:', error);
		}
	};
	const handleSubmitKaryawan = async (e) => {
		e.preventDefault();
		try {
			await axios.post(`api/karyawan`, { ...formDataPegawaiKaryawan, userID }, header);
			fetchKaryawan()
			Swal.fire({
				icon: "success",
				title: "Tersimpan",
				showConfirmButton: true,
				timer: 1000
			});
		} catch (error) {
			console.error('Error updating Karyawan data:', error);
		}
	};

	// Fungsi untuk menyimpan data riwayat penelitian
	const handleSubmitPenelitian = async (e) => {
		e.preventDefault();
		try {
			for (const penelitian of riwayatPenelitian) {
				if (penelitian.penelitianID) {
					await axios.patch(`api/penelitian/${penelitian.penelitianID}`, { ...penelitian, dosenID: formDataPegawaiDosen.dosenID }, header);
				} else {
					const response = await axios.post(`api/penelitian`, { ...penelitian, dosenID: formDataPegawaiDosen.dosenID }, header);
					// Tambahkan penelitianID dari response ke riwayatPenelitian
					setRiwayatPenelitian(prev => prev.map(item =>
						item.judulPenelitian === penelitian.judulPenelitian ? { ...item, penelitianID: response.data.penelitianID } : item
					));
				}
			}
			Swal.fire({
				icon: "success",
				title: "Riwayat Penelitian Tersimpan",
				showConfirmButton: true,
				timer: 1000
			});
		} catch (error) {
			console.error('Error updating penelitian data:', error);
		}
	};

	// Fungsi untuk menyimpan data riwayat pengabdian
	const handleSubmitPengabdian = async (e) => {
		e.preventDefault();
		try {
			for (const pengabdian of riwayatPengabdian) {
				if (pengabdian.pengabdianID) {
					await axios.patch(`api/pengabdian/${pengabdian.pengabdianID}`, { ...pengabdian, dosenID: formDataPegawaiDosen.dosenID }, header);
				} else {
					const response = await axios.post(`api/pengabdian`, { ...pengabdian, dosenID: formDataPegawaiDosen.dosenID }, header);
					setRiwayatPengabdian(prev => prev.map(item =>
						item.judulPengabdian === pengabdian.judulPengabdian ? { ...item, pengabdianID: response.data.pengabdianID } : item
					));
				}
			}
			Swal.fire({
				icon: "success",
				title: "Riwayat Pengabdian Tersimpan",
				showConfirmButton: true,
				timer: 1000
			});
		} catch (error) {
			console.error('Error updating pengabdian data:', error);
		}
	};

	// Fungsi untuk menyimpan data riwayat publikasi
	const handleSubmitPublikasi = async (e) => {
		e.preventDefault();
		try {
			for (const publikasi of riwayatPublikasi) {
				if (publikasi.publikasiID) {
					await axios.patch(`api/publikasi/${publikasi.publikasiID}`, { ...publikasi, dosenID: formDataPegawaiDosen.dosenID }, header);
				} else {
					const response = await axios.post(`api/publikasi`, { ...publikasi, dosenID: formDataPegawaiDosen.dosenID }, header);
					// Tambahkan publikasiID dari response ke riwayatPublikasi
					setRiwayatPublikasi(prev => prev.map(item =>
						item.judulPublikasi === publikasi.judulPublikasi ? { ...item, publikasiID: response.data.publikasiID } : item
					));
				}
			}
			Swal.fire({
				icon: "success",
				title: "Riwayat Publikasi Tersimpan",
				showConfirmButton: true,
				timer: 1000
			});
		} catch (error) {
			console.error('Error updating publikasi data:', error);
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false); // Function to close Snackbar
	};

	const handleDeleteKaryawan = async () => {
		const result = await Swal.fire({
			title: 'Apakah Anda yakin?',
			text: "Data karyawan ini akan dihapus!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Ya, hapus!'
		});

		if (result.isConfirmed) {
			try {
				await axios.delete(`api/karyawan/${userID}`, header); // Menggunakan userID untuk menghapus dari API
				setSnackbarMessage("Data karyawan berhasil dihapus!"); // Set Snackbar message
				setSnackbarOpen(true); // Open Snackbar
				setFormDataPegawaiKaryawan([])
				// navigate(`/admin/pegawai/${userID}`, { state: { activeTab: "formDataPegawai" } });

				// Reset form data or perform any other necessary actions
			} catch (error) {
				console.error('Error deleting karyawan data:', error);
			}
		}
	};
	const handleDeleteDosen = async () => {
		const result = await Swal.fire({
			title: 'Apakah Anda yakin?',
			text: "Data Dosen ini akan dihapus!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Ya, hapus!'
		});

		if (result.isConfirmed) {
			try {
				await axios.delete(`api/dosen/${userID}`, header); // Menggunakan userID untuk menghapus dari API
				setSnackbarMessage("Data Dosen berhasil dihapus!"); // Set Snackbar message
				setSnackbarOpen(true); // Open Snackbar
				setFormDataPegawaiDosen([])
				// navigate(`/admin/pegawai/${userID}`, { state: { activeTab: "formDataPegawai" } });

				// Reset form data or perform any other necessary actions
			} catch (error) {
				console.error('Error deleting dosen data:', error);
			}
		}
	};

	console.log(riwayatPenelitian);

	return (
		<div className=' w-full'>
			<div className="bg-[#31525e] text-white mb-4 py-2 px-4">
				<label className="flex text-xl uppercase font-bold ">Pilih Peran Pegawai</label>
				<div className="flex items-center">
					<input
						id='karyawan'
						type="checkbox"
						name="karyawan"
						checked={activeCheck === 'karyawan'}
						onChange={() => setActiveCheck('karyawan')}
						className="mr-2"
					/>
					<label htmlFor='karyawan' className=' mr-4'>Karyawan</label>
					<input
						id='dosen'
						type="checkbox"
						name="dosen"
						checked={activeCheck === 'dosen'}
						onChange={() => setActiveCheck('dosen')}
						className="mr-2"
					/>
					<label htmlFor='dosen' className="">Dosen</label>

				</div>
			</div>
			{activeCheck === 'dosen' && (

				formDataPegawaiDosen?.userID || formDataPegawaiDosen?.dosenID ? (
					// start form dosen
					<>

						<div className=' grid grid-cols-4 bg-slate-200 p-6 '>
							<div className="flex flex-col mb-4 mr-[-1px] z-10">
								<button onClick={() => setActiveTab('data')} className={` text-left text-[#a83135] p-2 ${activeTab === 'data' ? 'font-bold bg-white border-t border-b border-l border-[#c4c4c4]' : ''}`}>Data Dosen</button>
								<button
									onClick={() => formDataPegawaiDosen?.dosenID && setActiveTab('penelitian')}
									className={`text-left text-[#a83135] p-2 ${activeTab === 'penelitian' ? 'font-bold bg-white border-t border-b border-l border-[#c4c4c4]' : ''} ${!formDataPegawaiDosen?.dosenID ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
									disabled={!formDataPegawaiDosen?.dosenID}
								>
									Penelitian
								</button>
								<button
									onClick={() => formDataPegawaiDosen?.dosenID && setActiveTab('pengabdian')}
									className={`text-left text-[#a83135] p-2 ${activeTab === 'pengabdian' ? 'font-bold bg-white border-t border-b border-l border-[#c4c4c4]' : ''} ${!formDataPegawaiDosen?.dosenID ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
									disabled={!formDataPegawaiDosen?.dosenID}
								>
									Pengabdian
								</button>
								<button
									onClick={() => formDataPegawaiDosen?.dosenID && setActiveTab('publikasi')}
									className={`text-left text-[#a83135] p-2 ${activeTab === 'publikasi' ? 'font-bold bg-white border-t border-b border-l border-[#c4c4c4]' : ''} ${!formDataPegawaiDosen?.dosenID ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
									disabled={!formDataPegawaiDosen?.dosenID}
								>
									Publikasi Karya
								</button>
							</div>
							<div className=' bg-white border border-[#c4c4c4]  col-span-3 p-4'>
								{activeTab === 'data' && (
									<form className=' ' id='formDosen' onSubmit={handleSubmitDosen}>
										<div className='p-2'>
											<div className="mb-4">
												<label className="flex text-sm font-medium text-gray-700">Fakultas:</label>
												<select name="fakultas" value={formDataPegawaiDosen?.fakultas?.fakultasID || formDataPegawaiDosen?.fakultas} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
													<option value="">Pilih Fakultas</option>
													{fakultasList.map(fakultas => (
														<option key={fakultas.fakultasID} value={fakultas.fakultasID}>{fakultas.nama_fakultas}</option>
													))}
												</select>
											</div>
											<div className="mb-4">
												<label className="flex text-sm font-medium text-gray-700">NIDN:</label>
												<input type="number" name="nidn" value={formDataPegawaiDosen?.nidn || ''} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Masukkan NIDN" />
											</div>
											<div className="mb-4">
												<label className="flex text-sm font-medium text-gray-700">Jabatan:</label>
												<input type="text" name="jabatan" value={formDataPegawaiDosen?.jabatan || ''} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Masukkan Jabatan" />
											</div>
										</div>
										<button type="submit" className="mt-4 w-full bg-[#51242c] text-white font-semibold py-2 rounded-md hover:bg-[#7c414c]">Simpan</button>

									</form>
								)}
								{activeTab === 'penelitian' && (
									<form onSubmit={handleSubmitPenelitian}>
										{riwayatPenelitian?.map((item, index) => (
											<div key={index} className="flex items-end mb-2">
												<div className=' flex items-center h-10 pr-2 ' >{index + 1}</div>
												<div className='w-full mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Judul Penelitian:</label>
													<input type="text" placeholder='Masukan Judul Penelitian' name="judulPenelitian" value={item.judulPenelitian} onChange={(e) => handleChangeItem(setRiwayatPenelitian, index, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<div className='w-full mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Link Penelitian:</label>
													<input type="text" placeholder='https://link-penelitian' name="linkPenelitian" value={item.linkPenelitian || ''} onChange={(e) => handleChangeItem(setRiwayatPenelitian, index, e)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<div className='mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Tahun Penelitian:</label>
													<input type="number" placeholder=' Tahun' name="tahunPenelitian" value={item.tahunPenelitian} onChange={(e) => handleChangeItem(setRiwayatPenelitian, index, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<button type="button" onClick={() => handleRemoveItem(setRiwayatPenelitian, index, item.penelitianID, 'api/penelitian')} className="py-2 bg-red-500 p-4 h-10 text-white flex justify-center items-center">
													<FaTrashAlt />
												</button>
											</div>
										))}
										<div className='w-full flex justify-end'>
											<button type="button" onClick={() => handleAddItem(setRiwayatPenelitian, { judulPenelitian: '', linkPenelitian: '', tahunPenelitian: '' })} className="mt-4  text-[#7c414c] font-semibold py-2 px-3 rounded-md border border-[#7c414c] ">+ Tambah Penelitian</button>
										</div>
										<button disabled={riwayatPenelitian.length === 0} type="submit" className={`mt-4 w-full disabled:text-gray-300 disabled:cursor-not-allowed disabled:bg-[#765b60] bg-[#51242c] text-white font-semibold py-2 rounded-md hover:bg-[#7c414c]`}>Simpan Penelitian</button>
									</form>
								)}
								{activeTab === 'pengabdian' && (
									<form onSubmit={handleSubmitPengabdian}>
										{riwayatPengabdian?.map((item, index) => (
											<div key={index} className="flex items-end mb-2">
												<div className='w-full mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Judul Pengabdian:</label>
													<input type="text" placeholder='Masukan Judul Pengabdian' name="judulPengabdian" value={item.judulPengabdian} onChange={(e) => handleChangeItem(setRiwayatPengabdian, index, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<div className='w-full mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Link Pengabdian:</label>
													<input type="text" placeholder='Masukan Link Pengabdian' name="linkPengabdian" value={item.linkPengabdian || ''} onChange={(e) => handleChangeItem(setRiwayatPengabdian, index, e)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<div className='mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Tahun:</label>
													<input type="number" placeholder='Tahun' name="tahunPengabdian" value={item.tahunPengabdian} onChange={(e) => handleChangeItem(setRiwayatPengabdian, index, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<button type="button" onClick={() => handleRemoveItem(setRiwayatPengabdian, index, item.pengabdianID, 'api/pengabdian')} className="py-2 bg-red-500 w-28 h-10 text-white flex justify-center items-center">
													<FaTrashAlt />
												</button>
											</div>
										))}
										<div className='w-full flex justify-end'>
											<button type="button" onClick={() => handleAddItem(setRiwayatPengabdian, { judulPengabdian: '', linkPengabdian: '', tahunPengabdian: '' })} className="mt-4  text-[#7c414c] font-semibold py-2 px-3 rounded-md border border-[#7c414c] ">+ Tambah Pengabdian</button>
										</div>
										<button disabled={riwayatPengabdian.length === 0} type="submit" className={`mt-4 w-full disabled:text-gray-300 disabled:cursor-not-allowed disabled:bg-[#765b60] bg-[#51242c] text-white font-semibold py-2 rounded-md hover:bg-[#7c414c]`}>Simpan Pengabdian</button>
									</form>
								)}
								{activeTab === 'publikasi' && (
									<form onSubmit={handleSubmitPublikasi}>
										{riwayatPublikasi?.map((item, index) => (
											<div key={item.publikasiID} className="flex items-end mb-2">
												<div className='w-full mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Judul Publikasi:</label>
													<input type="text" placeholder=' Masukan Judul Publikasi' name="judulPublikasi" value={item.judulPublikasi} onChange={(e) => handleChangeItem(setRiwayatPublikasi, index, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<div className='w-full mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Link Publikasi:</label>
													<input type="text" placeholder='Masukan Link Publikasi' name="linkPublikasi" value={item.linkPublikasi || ''} onChange={(e) => handleChangeItem(setRiwayatPublikasi, index, e)} className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<div className='mx-1'>
													<label className="flex text-sm font-medium text-gray-700">Tahun:</label>
													<input type="number" placeholder=' Tahun' name="tahunPublikasi" value={item.tahunPublikasi} onChange={(e) => handleChangeItem(setRiwayatPublikasi, index, e)} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
												</div>
												<button type="button" onClick={() => handleRemoveItem(setRiwayatPublikasi, index, item.publikasiID, 'api/publikasi')} className="py-2 bg-red-500 w-28 h-10 text-white flex justify-center items-center">
													<FaTrashAlt />
												</button>
											</div>
										))}
										<div className='w-full flex justify-end'>
											<button type="button" onClick={() => handleAddItem(setRiwayatPublikasi, { judulPublikasi: '', linkPublikasi: '', tahunPublikasi: '' })} className="mt-4  text-[#7c414c] font-semibold py-2 px-3 rounded-md border border-[#7c414c] ">+ Tambah Publikasi</button>
										</div>
										<button disabled={riwayatPublikasi.length === 0} type="submit" className={`mt-4 w-full disabled:text-gray-300 disabled:cursor-not-allowed disabled:bg-[#765b60]  bg-[#51242c] text-white font-semibold py-2 rounded-md hover:bg-[#7c414c]`}>Simpan Publikasi</button>
									</form>
								)}
							</div>
						</div>
						{formDataPegawaiDosen.dosenID && (
							<div className=''>
								<button type="button" onClick={handleDeleteDosen} className=" mt-8 w-full bg-red-500 text-white flex font-semibold py-2 rounded-md  justify-center items-center  hover:bg-red-700"> <span className=' pr-2'><FaExclamationTriangle /></span> Hapus Dari Dosen </button>
							</div>
						)}
					</>
					// end form dosen

				) : (
					<div className=' w-full flex justify-center items-center'>
						<button onClick={() => setFormDataPegawaiDosen({ ...formDataPegawaiDosen, userID })} className='p-4 flex justify-center items-center bg-slate-500 text-white rounded'><span className=' pr-2'><FaEdit /></span> Isi data Dosen</button>
					</div>
				)
			)}
			{
				activeCheck === 'karyawan' && (
					formDataPegawaiKaryawan?.userID || formDataPegawaiKaryawan?.karyawanID ? (
						<div className='w-full'>
							<form className='px-6' id='formKaryawan' onSubmit={handleSubmitKaryawan}>
								<div className='grid grid-cols-2'>
									<div className='p-2'>
										<div className="mb-4">
											<label className="flex text-sm font-medium text-gray-700">NIP:</label>
											<input type="number" name="nip" value={formDataPegawaiKaryawan?.nip || ''} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Masukkan NIP" />
										</div>
										<div className="mb-4">
											<label className="flex text-sm font-medium text-gray-700">Status Karyawan:</label>
											<select name="statusKaryawan" value={formDataPegawaiKaryawan?.statusKaryawan || ''} onChange={handleChangeDataPegawai} className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
												<option value="">Pilih</option>
												<option value="Tetap">Tetap</option>
												<option value="Kontrak">Kontrak</option>
												<option value="Magang">Magang</option>
											</select>
										</div>
									</div>
									<div className='p-2'>
										<div className="mb-4">
											<label className="flex text-sm font-medium text-gray-700">Devisi:</label>
											<select name="devisi" value={formDataPegawaiKaryawan?.devisi?.devisiID || formDataPegawaiKaryawan?.devisi} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
												<option value="">Pilih Devisi</option>
												{devisiList.map(devisi => (
													<option key={devisi.devisiID} value={devisi.devisiID}>{devisi.nama_devisi}</option>
												))}
											</select>
										</div>
										<div className="mb-4">
											<label className="flex text-sm font-medium text-gray-700">Jabatan:</label>
											<input type="text" name="jabatan" value={formDataPegawaiKaryawan?.jabatan || ''} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Masukkan Jabatan" />
										</div>
										<div className="mb-4">
											<label className="flex text-sm font-medium text-gray-700">Tanggal Bergabung:</label>
											<input type="date" name="tanggalBergabung" value={formDataPegawaiKaryawan?.tanggalBergabung || ''} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Pilih Tanggal" />
										</div>
										<div className="mb-4">
											<label className="flex text-sm font-medium text-gray-700">Gaji:</label>
											<input type="text" name="gaji" value={formDataPegawaiKaryawan?.gaji || ''} onChange={handleChangeDataPegawai} required className="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Masukkan Gaji" />
										</div>
									</div>
								</div>
								<button type="submit" className="mt-4 w-full bg-[#31525e] text-white font-semibold py-2 rounded-md hover:bg-[#547a87]">Simpan</button>
							</form>
							{formDataPegawaiKaryawan.karyawanID && (
								<div className='px-6'>
									<button type="button" onClick={handleDeleteKaryawan} className=" mt-8 w-full bg-red-500 text-white flex font-semibold py-2 rounded-md  justify-center items-center  hover:bg-red-700"> <span className=' pr-2'><FaExclamationTriangle /></span> Hapus Dari Karyawan </button>
								</div>
							)}
						</div>
					) : (
						<div className=' w-full flex justify-center items-center'>
							<button onClick={() => setFormDataPegawaiKaryawan({ ...formDataPegawaiKaryawan, userID })} className='p-4 flex justify-center items-center bg-slate-500 text-white rounded'><span className=' pr-2'><FaEdit /></span> Isi data Karyawan</button>
						</div>
					)
				)
			}
			{
				!activeCheck && (
					<>
						<div>
							Tidak Ada Data
						</div>
					</>
				)
			}
			<Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleCloseSnackbar}>
				<MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
					{snackbarMessage}
				</MuiAlert>
			</Snackbar>
		</div >
	);
};

export default EmployeeDataForm;
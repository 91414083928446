import axios from "axios"
import { useEffect, useState } from "react"
import { FaBuilding, FaFileContract, FaUser } from "react-icons/fa"

const DashboardAdmin = ({ data }) => {
    const header = {
        headers: { 'Authorization': `Bearer ${data.token}` }
    }
    const [item, setItem] = useState();



    useEffect(() => {
        const getData = async () => {
            await axios.get(`/api/users/count`, header)
                .then(response => {
                    setItem(response.data);
                })
        }
        getData()

    }, [])

    console.log(item);

    return (
        <>

            <div className="py-5">
                <main className="h-full overflow-y-auto">
                    <div className="px-4  mx-auto grid">

                        <div className=" mb-8 ">
                            <div className="flex justify-center items-center p-4 bg-white rounded-lg shadow-xs ">

                                <div>
                                    <div className=' font-bold text-2xl  ' >Sistem Informasi Kepegawaian (SIMPEG)</div>
                                </div>
                            </div>
                        </div>
                        <div className=" mb-4 flex items-center p-4 bg-white rounded-lg shadow-xs ">
                            <div className="p-3 text-2xl mr-4 text-orange-500 bg-orange-100 rounded-full ">
                                <FaUser />
                            </div>
                            <div>
                                <p className="mb-2 text-sm font-medium text-gray-600 d">
                                    Total Pegawai & Dosen
                                </p>
                                <p className="text-lg font-semibold text-gray-700 d">
                                    {item?.totalUser}
                                </p>
                            </div>
                        </div>
                        {/* <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                            <div className="  flex items-center p-4 bg-white rounded-lg shadow-xs ">
                                <div className="p-3 text-2xl mr-4 text-blue-500 bg-blue-100 rounded-full ">
                                    <FaUser />
                                </div>
                                <div>
                                    <p className="mb-2 text-sm font-medium text-gray-600 d">
                                        Jumlah Fakultas
                                    </p>
                                    <p className="text-lg font-semibold text-gray-700 d">
                                        {item?.totalFakultas}
                                    </p>
                                </div>
                            </div>
                            <div className="  flex items-center p-4 bg-white rounded-lg shadow-xs ">
                                <div className="p-3 text-2xl mr-4 text-green-500 bg-green-100 rounded-full ">
                                    <FaBuilding />
                                </div>
                                <div>
                                    <p className="mb-2 text-sm font-medium text-gray-600 d">
                                        Jumlah Devisi
                                    </p>
                                    <p className="text-lg font-semibold text-gray-700 d">
                                        {item?.totalDevisi}
                                    </p>
                                </div>
                            </div>


                        </div> */}


                    </div>
                </main>
            </div>
        </>
    )
}

export default DashboardAdmin
import unes_s from "./../../assets/img/unes-small.png";
import fb from "./../../assets/img/fb.png";
import ig from "./../../assets/img/ig.png";
import wa from "./../../assets/img/wa.png";
import { FaGlobe } from "react-icons/fa";
function Footer() {
    return (
        <>
            {/* footer */}
            <div className="w-full  h-full relative" style={{ filter: "drop-shadow(1px -4px 10px rgba(0,0,0,0.25))" }}>
                <div className=" bg-[#51242c] md:grid md:grid-cols-3  grid-cols-none min-h-[150px] w-full h-full   p-8 " style={{ filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.25))" }}>
                    <div className=" flex px-2 items-center">
                        <img src={unes_s} className="w-[72px] pr-3 h-auto object-contain " alt="lol" />
                        <div>
                            <p className=" text-base font-bold text-left text-[#e2e2e2]">
                                UNIVERSITAS EKASAKTI
                            </p>
                            <p className="w-full font-light text-sm text-left text-[#e2e2e2]">
                                Jl. Veteran No. 26B Purus, Kec. Padang Barat
                            </p>
                        </div>
                    </div>
                    <div className=" flex justify-center px-2 w-full h-full">
                        <div className=' w-full h-full'>
                            <div className="  text-xl font-bold text-left text-[#e2e2e2]">
                                Link Terkait
                            </div>
                            <div className=" text-base text-left text-[#e2e2e2]">
                                <a className=" flex items-center" href="https://unespadang.ac.id">
                                    <FaGlobe />
                                    <span className="px-2 text-base text-left text-[#e2e2e2]">
                                        unespadang.ac.id
                                    </span>
                                </a>
                                <a className=" flex items-center" href="https://siakad.unespadang.ac.id">
                                    <FaGlobe />
                                    <span className="px-2 py-1 text-base text-left text-[#e2e2e2]">
                                        siakad.unespadang.ac.id
                                    </span>
                                </a>


                            </div>
                        </div>
                    </div>
                    <div className=" flex md:justify-end justify-center items-center ">
                        <a href="https://www.facebook.com/profile.php?id=100088674894953">

                            <img
                                src={fb}
                                className="w-[44.27px] object-contain p-1" alt="img"
                            />
                        </a>
                        <a href="https://www.instagram.com/universitasekasakti.aai_padang">
                            <img
                                src={ig}
                                className="w-[44.27px]   object-contain p-1" alt="img"
                            />
                        </a>

                        <a href="https://wa.me/6285668419278">
                            <img
                                src={wa}
                                className="w-[44.27px] object-contain p-1" alt="img"
                            />
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Footer;